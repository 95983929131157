import '../scss/style.scss';
import jQuery from 'jquery';
import Vue from 'vue'

!function ($) {
    // add classes by scroll position
    let position = $(window).scrollTop();
    let winHeight = $(window).height();
    let docHeight = $(document).height();
    $(window).scroll(function () {
        let scrollPercent = (position) / (docHeight - winHeight);
        let scrollPercentRounded = Math.round(scrollPercent*100);
        let scroll = $(window).scrollTop();
        let nav = $(".navigation");
        if (scroll >= 120 && scroll > position) {
            nav.removeClass("color");
            nav.addClass("hide");
        } else if( scroll <= 120) {
            nav.removeClass("color");
        }
        else {
            nav.removeClass("hide");
            nav.addClass("color");
        }
        position = scroll;
    });

}(jQuery);

var app = new Vue({
    el: '#app',
    data: {
        message: 'Hello Vue!'
    },
    ready: function() {
        console.log("YAY");
    },
});